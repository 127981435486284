<template>
  <div id="wr-result" wild-rift-result data-exec-modal="WariWariResultModal">
    <official-league-game-info-template :title="title" @more-click="resultModalOpen">
      <div class="context">
        <div class="result-con">
          <div class="table-holder">
            <table>
              <thead>
                <tr>
                  <th>순위</th>
                  <th>팀</th>
                  <th>순위 보상</th>
                  <th>8강 보상</th>
                  <th>한국대표 시드권</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(team, index) in teamList" :key="`team-result-${index}`" :class="{'out': index > 4}">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <p>{{ team.name }}</p>
                  </td>
                  <td>{{ team.reward.rank || '-' }}</td>
                  <td>{{ team.reward.eight || '-' }}</td>
                  <td>
                    <svg v-if="index <= 4" width="20" height="20" viewBox="0 0 20 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM8.5625 14.9375L3.625 10L5.125 8.5L8.625 12L14.875 5.875L16.375 7.375L8.5625 14.9375Z"
                        fill="#33C6FF" />
                    </svg>
                    <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.6986 0L9.0411 6.65753L2.30137 0L0 2.30137L6.65753 9.0411L0 15.6986L2.30137 18L9.0411 11.3425L15.6986 18L18 15.6986L11.3425 9.0411L18 2.30137L15.6986 0Z"
                        fill="#ED5D5D" />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </official-league-game-info-template>
  </div>
</template>

<script>
import WariWariResultModal from '@/views/components/gameHome/wr/gameInfo/WariWariResultModal.vue';
import OfficialLeagueGameInfoTemplate from '@/views/components/gameHome/OfficialLeagueGameInfoTemplate.vue';

export default {
  name: 'WariWariResult',
  components: { OfficialLeagueGameInfoTemplate },
  data() {
    return {
      title: {
        title: 'RESULT',
        titleKo: '대회 결과',
        subTitle: '총 상금 2,500만원의 주인공과 한국대표 선발전 시드권 획득 5팀을 공개합니다.',
        more: true,
      },
      teamList: [
        {
          name: 'Break Frame Gaming',
          reward: {
            rank: '1,000만',
            eight: '100만',
          },
        },
        {
          name: 'GLUK Gaming',
          reward: {
            rank: '400만',
            eight: '100만',
          },
        },
        {
          name: 'LGTM',
          reward: {
            rank: '200만',
            eight: '100만',
          },
        },
        {
          name: 'Baby is 87',
          reward: {
            rank: '100만',
            eight: '100만',
          },
        },
        {
          name: 'Team NC',
          reward: {
            rank: '',
            eight: '100만',
          },
        },
        {
          name: 'NC.',
          reward: {
            rank: '',
            eight: '100만',
          },
        },
        {
          name: 'Ai HappyWin',
          reward: {
            rank: '',
            eight: '100만',
          },
        },
        {
          name: 'Say GGs',
          reward: {
            rank: '',
            eight: '100만',
          },
        },
      ],
    };
  },
  methods: {
    resultModalOpen() {
      this.$modal(WariWariResultModal, {});
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[wild-rift-result] {
  .sub-title { // .max-w(348);
  }
  .context { .h; .mt(30); .bgc(#24262c); .-a(rgba(255, 255, 255, 0.1)); .br(8);
    [no-data] { .bg('/img/event/wariwari/WR_logo_w.svg'); .no-repeat; .bg-c;
      .text { .max-w(239); .mh-c; .fs(20, 28); .c(white); .bold; .ls(-0.83); .font-g; .tc; .ls(-1); }
    }
    .result-con { .mh-c; .p(18, 20); .crop;
      .table-holder { overflow-x: auto;
        > table { .min-w(350); table-layout: fixed;
          tr {
            th { .h(44); .p(0, 7); .fs(12, 14.4); .medium; .c(#fff); .ls(-0.62); .tc; .font-g; .bgc(#7488f7); .-a(#24262c, 2);
              &:nth-of-type(1) { .w(15%); }
              &:nth-of-type(2) { .w(30%); }
              &:nth-of-type(3) { .w(15%); .min-w(51); }
              &:nth-of-type(4) { .w(15%); .min-w(51); }
              &:nth-of-type(5) { .w(25%); }
            }
            td { .h(49); .fs(12, 16); .medium; .c(#fff); .font-g; .tc; .bgc(#363840); .-a(#24262c, 2);
              &:nth-of-type(2) { .p(0, 7);
                p { .ellipsis(2); }
              }
            }

            &.out {
              td { .o(0.4); }
            }
          }
        }
      }
    }
  }
  @media (@tl-up) {
    .context { .h(496); .mt(40);
      [no-data] {
        .text { .max-w(287); .mh-c; .fs(24, 36); }
      }
      .result-con { .p(28);
        .table-holder {
          > table {
            tr {
              th { .fs(16, 19.2); .ls(-1);
                &:nth-of-type(1) { .w(12%); }
                &:nth-of-type(2) { .w(27%); }
                &:nth-of-type(3) { .w(17%); }
                &:nth-of-type(4) { .w(17%); }
                &:nth-of-type(5) { .w(27%); }
              }
              td { .fs(14, 16); }
            }
          }
        }
      }
    }
  }
}
</style>
